<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>{{ $t("survey.surveys") }}</li>
            <li>{{ $t("evaluation_survey.surveys") }}</li>
          </ul>
        </nav>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left">
            <span> {{ $t("evaluation_survey.list") }} </span>
          </h2>
        </div>
        <div class="section-header-right">
          <router-link :to="{ name: 'company.evaluation-survey.add' }">
            <button class="btn btn-default" type="button" aria-expanded="false">
              <i class="uil-plus"></i> {{ $t("evaluation_survey.add") }}
            </button>
          </router-link>
        </div>
      </div>

      <template v-if="errorMessages">
        <div
            v-for="(error, index) in errorMessages"
            :key="index"
            class="uk-alert-danger"
            uk-alert
        >
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div class="card">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="d-flex justify-content-between align-items-center">
            <!-- <h4 class="d-inline-block mb-0"></h4> -->
            <div class="d-flex justify-content-start align-items-end">

              <div class="filter-is_active">
                <label>{{ $t('evaluation_survey.status') }}</label>
                <select v-model="filter.is_active">
                  <option :value="null">-</option>
                  <option :value="1">{{ $t('evaluation_survey.active') }}</option>
                  <option :value="0">{{ $t('evaluation_survey.inactive') }}</option>
                </select>
              </div>

              <div class="row" style="width:100%">
                <div class=" col-md-2  search-icon ">
                  <i class="uil-search"></i>
                </div>
                <div
                    class="col-md-11 uk-search uk-search-navbar"
                    style="margin-left:-2rem"
                >
                  <input
                      class="uk-search-input shadow-0 uk-form-small"
                      type="search"
                      v-model="filter.search"
                      :placeholder="$t('general.search')"
                  />
                </div>
              </div>


            </div>
            <div class="d-flex position-relative">
              <!-- Shorting -->
              <a
                  href="#"
                  class="btn btn-icon btn-hover btn-circle"
                  :uk-tooltip="$t('filters.sort_list')"
                  title=""
                  aria-expanded="false"
              >
                <i class="uil-list-ui-alt"></i>
              </a>
              <div
                  uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up"
                  class="uk-dropdown"
              >
                <ul class="uk-nav uk-dropdown-nav">
                  <li @click="sortOrder('name', 'asc')">
                    <a href="javascript:void(0)">{{ $t("sorting.a_z") }}</a>
                  </li>
                  <li @click="sortOrder('name', 'desc')">
                    <a href="javascript:void(0)">{{ $t("sorting.z_a") }}</a>
                  </li>
                  <li @click="sortOrder('created_at', 'asc')">
                    <a href="javascript:void(0)">
                      {{ $t("sorting.created_at_asc") }}</a
                    >
                  </li>
                  <li @click="sortOrder('created_at', 'desc')">
                    <a href="javascript:void(0)">
                      {{ $t("sorting.created_at_desc") }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div v-if="loading"
             style="position: absolute; height: 100%; width: 100%; z-index: 999; background: rgba(238, 238, 238, 0.5); display: flex; justify-content: center; align-items: center">
          <div uk-spinner="ratio: 4" class="text-info"></div>
        </div>
        <!-- Table -->
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
            <tr>
              <th scope="col">{{ $t("evaluation_survey.name") }}</th>
              <th scope="col">{{ $t("evaluation_survey.company") }}</th>
              <th scope="col">{{ $t("general.added_at") }}</th>
              <th scope="col">{{ $t("general.status") }}</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody class="list">
            <!--user item-->
            <tr v-for="survey in surveys">
              <td>
                <template v-if="survey.is_default">
                  <span class="name h6 mb-0 text-sm">
                    {{ survey.name }}
                  </span>
                  <i
                      class="uil-check-circle"
                      :uk-tooltip="$t('survey.default_description')"
                      title=""
                      aria-expanded="false"
                  ></i>
                </template>
                <router-link
                    :to="{
                      name: 'company.evaluation-survey.edit',
                      params: { id: survey.id },
                    }"
                    class="name h6 mb-0 text-sm"
                    v-else
                >
                  {{ survey.name }}
                </router-link>
              </td>
              <td>{{ survey.company_name }}</td>
              <td>{{ survey.created_at }}</td>
              <td>{{ survey.is_active ? $t('evaluation_survey.active') : $t('evaluation_survey.inactive') }}</td>
              <td class="text-right">
                <!-- Actions -->
                <div class="actions ml-3">

                  <a class="btn btn-icon btn-hover btn-sm btn-circle" href="javascript:void(0)"
                     :uk-tooltip="$t('general.view')" @click="showItem(survey)">
                    <i class="uil-eye"></i>
                  </a>

                  <router-link
                      class="btn btn-icon btn-hover btn-sm btn-circle"
                      :to="{
                      name: 'company.evaluation-survey.edit',
                      params: { id: survey.id },
                    }"
                      :uk-tooltip="$t('general.edit')"
                      title=""
                      aria-expanded="false"
                  >
                    <i class="uil-edit"></i>
                  </router-link>
                  <a href="javascript: void(0)"
                     class="btn btn-icon btn-hover btn-sm btn-circle"
                     aria-expanded="false"
                     :uk-tooltip="$t('general.copy')"
                     @click="duplicate(survey.id)">
                    <i class="uil-copy text-info"></i>
                  </a>
                  <a
                      href="javascript:void(0)"
                      class="btn btn-icon btn-hover btn-sm btn-circle"
                      :class="survey.is_default ?'disabled' :''"
                      :uk-tooltip="$t('general.delete')"
                      title=""
                      aria-expanded="false"
                      @click="removeItem(survey.id, survey.assignments_count)"
                  >
                    <i class="uil-trash text-danger"></i>
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <pagination
              v-if="pagination && pagination.hasOwnProperty('links')"
              :pagination="pagination"
              @changePage="setPage($event)"
          ></pagination>
        </div>
      </div>
    </div>

    <modal name="survey_list_modal" :adaptive="false" height="auto" width="700">
      <QuestionList
          :survey="survey_item"
          :modal_title="survey_name"
          :modal_name="'survey_list_modal'"
          :hideSubmit="true"
      ></QuestionList>
    </modal>

  </div>
</template>

<script>
import Vue from "vue";
import store from "@/core/services";
import moment from "moment";
import module, {
  BASE_URL,
  GET_ITEMS,
  MODULE_NAME,
  ITEMS,
  ERROR,
  LOADING,
  DELETE_ITEM,
  DUPLICATE_ITEM
} from "@/core/services/store/evaluation_survey.module";
import Pagination from "@/assets/components/Pagination";
import QuestionList from "@/view/components/survey/QuestionList";
import Swal from "sweetalert2";


export default {
  name: "EvaluationSurveysList",
  components: {Pagination, QuestionList},
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(MODULE_NAME, module);
  },
  data() {
    return {
      //is_loading: false,
      pagination: null,
      activeItem: null,
      successMessage: null,
      errorMessages: [],
      page: 1,
      per_page: 10,
      filter: {
        search: null,
        is_active: null,
        company_id: null,
        order: "desc",
        sort: "id",
      },
      survey_item: {},
      survey_name: null,
    };
  },
  computed: {
    loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) {
      },
    },
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) {
      },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
    // isLoading() {
    //   return this.loading
    // },
    surveys() {
      let results = [];
      if (this.items === null || !("data" in this.items)) return [];
      this.pagination = this.items;

      for (let i = 0; i < this.items.data.length; i++) {
        let currentItem = this.items.data[i];
        if (currentItem) {
          results.push({
            id: currentItem.id,
            name: currentItem.name,
            company_name: currentItem.company ? currentItem.company.name : '-',
            questions: currentItem.questions,
            is_default: currentItem.is_default,
            is_active: currentItem.is_active,
            assignments_count: currentItem.assignments_count,
            created_at: currentItem.created_at
                ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
                : "-",
          });
        }
      }
      return results;
    },
  },
  methods: {
    deleteEvaluationSurvey(id) {
      this.$store
          .dispatch(MODULE_NAME + "/" + DELETE_ITEM, {
            url: BASE_URL + "/" + id,
          })
          .then(() => {
            if (!this.error) {
              this.successMessage = this.$t("general.successfully_deleted");
              this.filterResult();
            } else this.errorMessages.push(this.error)
          });
    },
    removeItem(id, assignments_count) {
      let self = this;
      if (assignments_count) {
        Swal.fire({
          title: "Bu anket bir eğitime bağlı. Silmek istediğinizden emin misiniz?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Vazgeç",
          confirmButtonColor: "#e31748",
        }).then((result) => {
          if (result.isConfirmed) {
            self.deleteEvaluationSurvey(id);
          }
        });
        return false;
      }

      Swal.fire({
        title: "Bu anketi silmek istediğinizden emin misiniz?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Vazgeç",
        confirmButtonColor: "#e31748",
      }).then((result) => {
        if (result.isConfirmed) {
          self.deleteEvaluationSurvey(id);
        }
      });

    },
    showItem(survey) {
      this.survey_item = survey;
      this.survey_name = survey.name;

      this.$modal.show("survey_list_modal");
    },
    duplicate(id) {

      this.$store.dispatch(MODULE_NAME + "/" + DUPLICATE_ITEM, {
        url: BASE_URL + "/duplicate/" + id,
      }).then(() => {
        this.filterResult();
      });

    },
    filterResult() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          search: this.filter.search,
          is_active: this.filter.is_active,
          company_id: this.company_id,
          page: this.page,
          per_page: this.per_page,
          order: this.filter.order,
          sort: this.filter.sort,
        },
      });
    },
    sortOrder(sort, order) {
      let self = this;
      self.page = 1;
      self.filter.sort = sort;
      self.filter.order = order;
      this.filterResult();
    },
    setPage(page) {
      let self = this;
      self.page = page;
      this.filterResult();
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
  },
  watch: {
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    "filter.is_active": function (val) {
      this.debouncedFilterSearch();
    },
    "filter.company_id": function (val) {
      this.debouncedFilterSearch();
    },
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessages() {
      this.debouncedResetValues();
    },
  },
  mounted() {
    this.filterResult();
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
}
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}


</style>