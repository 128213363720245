<template>
  <div :class="modal_name ? 'p-4' : 'px-4 pt-5 pb-4'">
    <button v-if="!modal_name" class="uk-modal-close-default" type="button" uk-close @click="closeModal"></button>
    <div class="section-header border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span> {{ modal_title || survey.name }} </span>
        </h2>
      </div>
    </div>
    <div v-if="errorMessages.length" class="uk-alert-danger" uk-alert>
      <template v-for="(error, index) in errorMessages">
        <span :key="index" v-if="error != null">
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red" class="m-0">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </span>
      </template>
    </div>
    <div v-if="successMessage" class="uk-alert-success" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color: green">
        <i class="uil-exclamation-triangle"></i> {{ successMessage }}
      </p>
    </div>
    <!-- Card header -->
    <div class="card-body actions-toolbar border-0" style="max-height: 50vh;overflow-y: scroll">
      <template v-for="(question, questionIndex) in questions">
        <div v-if="question !== null && question.type === 1" class="form-group row mb-3"
          :key="'new_question_box_' + questionIndex">
          <h6 class="col-12">{{ (questionIndex + 1) + '. ' + question.name }}</h6>
          <div class="col-12">
            <select v-model="question.answerPoint">
              <option v-for="option in question.options" :value="option.point">{{ option.label }}</option>
            </select>
          </div>
        </div>
        <div v-if="question !== null && question.type === 2" class="form-group row mb-3"
          :key="'new_question_box_' + questionIndex">
          <h6 class="col-12">{{ question.name }}</h6>
          <div class="col-12">
            <textarea v-model="question.answerText"></textarea>
          </div>
        </div>
      </template>
    </div>
    <div class="card-footer d-flex justify-content-center p-2">
      <button v-if="modal_name" @click="closeModal" class="btn btn-sm btn-secondary button btn-pill cursor-pointer mr-2">
        {{ $t("general.close") }}
      </button>

      <button @click="onSubmit" class="btn btn-sm btn-primary button btn-pill cursor-pointer" v-if="!hideSubmit">
        {{ $t("general.submit") }}
      </button>
    </div>
  </div>
</template>

<script>
import Template from "../../company/Mail/Template.vue";

export default {
  components: { Template },
  name: "QuestionList",
  props: {
    survey: {
      required: true,
      default: [],
    },
    modal_name: {
      required: true,
    },
    modal_title: {
      required: false,
    },
    hideSubmit: {
      required: false,
      default: false,
    }
  },
  data() {
    return {
      errorMessages: [],
      successMessage: null,
      answerText: ''
    };
  },
  computed: {
    questions() {
      let questions = [];
      if (this.survey && this.survey.questions.length) {
        this.survey.questions.forEach((question, index) => {
          let selfQuestion = Object.assign({}, question);
          let options = {};
          let answerPoint = null;
          switch (selfQuestion.type) {
            case 1:
              options = JSON.parse(selfQuestion.options);
              answerPoint = 1;
              break;
            case 2:
              options = {};
              answerPoint = 0;
              break;
          }
          questions[index] = {
            id: selfQuestion.id,
            name: selfQuestion.name,
            type: selfQuestion.type,
            answerPoint: answerPoint,
            options: options
          }
        });
      }
      return questions;
    }
  },
  methods: {
    makeQuestions() {
      if (!this.survey.name) return [];

      let questions = [];

      this.survey.questions.forEach((question, index) => {

        let selfQuestion = Object.assign({}, question);

        let options = {};
        let answerPoint = null;
        switch (selfQuestion.type) {
          case 1:
            options = JSON.parse(selfQuestion.options);
            answerPoint = 1;
            break;
          case 2:
            options = {};
            answerPoint = 0;
            break;
        }

        questions[index] = {
          id: selfQuestion.id,
          name: selfQuestion.name,
          type: selfQuestion.type,
          answerPoint: answerPoint,
          options: options
        }
      });
      // questions[0] = null;
      // questions.splice(0, 1);
      this.questions = questions;
    },
    onSubmit() {
      let self = this;
      self.resetMessages();
      let validated = true;
      let userAnswers = [];
      for (const [questionIndex, question] of Object.entries(self.questions)) {
        if (question.type === 1 && question.answerPoint == null) {
          self.errorMessages.push(self.$t("question.question_required").replace(":NAME", question.name));
          validated = false;
        }
        let answer = question.options[question.answerPoint]
        userAnswers.push({
          question_id: question.id,
          answer_point: question.answerPoint ?? 0,
          question_text: question.name,
          answer_text: question.answerText,
        })
      }
      if (validated) {
        self.$emit("onSubmit", {
          answers: userAnswers,
        },
          function (data) {
            if (!data.status) {
              if (data.code !== 422) {
                self.errorMessages.push(self.$t(data.error.message));
                return true;
              }
              self.showErrors(data.error.errors);
            } else {
              self.successMessage = self.$t('survey.saved_successfully')

              setTimeout(() => {
                self.closeModal();
              }, 1000)
            }
          }
        );
      }

      // for (const [question_id, answer] of Object.entries(self.answers)) {
      //   if (answer == null || answer.length == 0 || (typeof answer == 'string' && answer.length < 3)) {
      //     self.errorMessages[self.question_indexes[question_id]] = self
      //       .$t("question.question_required")
      //       .replace(":NAME", self.question_indexes[question_id] + 1);
      //   }
      // }
      // if (self.errorMessages.length == 0) {
      //   self.$emit(
      //     "onSubmit",
      //     {
      //       answers: self.answers,
      //     },
      //     function (data) {
      //       if (!data.status) {
      //         if (data.code != 422) {
      //           self.errorMessages.push(self.$t("errors." + data.code));
      //           return true;
      //         }
      //         self.showErrors(data.error.errors);
      //       }
      //     }
      //   );
      // }
    },
    showErrors(errors) {
      this.resetMessages();
      for (const [field, error] of Object.entries(errors)) {
        if (Array.isArray(error)) {
          error.forEach((text) => {
            this.errorMessages.push(text);
          });
        } else this.errorMessages.push(error);
      }
    },
    closeModal() {
      this.$emit('onClose', true);
      if (this.modal_name) this.$modal.hide(this.modal_name);
    },
    resetMessages() {
      this.errorMessages = [];
    },
  },
  watch: {
    errorMessages() {
      this.debouncedResetValues();
    },
  },
  mounted() {
    //this.questions = this.formattedSurvey;
    // this.makeQuestions();
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2500);
  },
};
</script>
<style scoped>
.line-height-1 {
  line-height: 1rem;
}
</style>

